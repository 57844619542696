<template>
  <div class="container">
    <Form ref="formCustom" :model="formCustom" :rules="ruleCustom" :label-width="100">
      <FormItem label="个人账号:">
        <div style="text-align:left;">{{ userInfo.name }}</div>
      </FormItem>
      <FormItem label="姓名:">
        <Input v-model="userInfo.nickName" readonly />
      </FormItem>
      <FormItem label="手机号:">
        <Input v-model="userInfo.serialNumber" readonly />
      </FormItem>
      <FormItem label="原密码:" prop="oldPassword">
        <Input type="password" v-model="formCustom.oldPassword"></Input>
      </FormItem>
      <FormItem label="新密码:" prop="newPassword">
        <Input type="password" v-model="formCustom.newPassword"></Input>
      </FormItem>
      <FormItem label="确认密码:" prop="passwdCheck">
        <Input type="password" v-model="formCustom.passwdCheck"></Input>
      </FormItem>
      <FormItem>
        <Button type="primary" @click="handleSubmit('formCustom')" class="btn">提交</Button>
        <Button @click="handleReset('formCustom')" style="margin-left: 8px">重置</Button>
      </FormItem>
    </Form>
  </div>
</template>
<script>
const { mapActions, mapState } = window.Vuex;
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (!/^[\x00-\xff^\s]{8,16}$/.test(value)) {
        callback(new Error("用户密码不能包含中文和空格"));
      } else {
        if (this.formCustom.passwdCheck !== "") {
          // 对第二个密码框单独验证
          this.$refs.formCustom.validateField("passwdCheck");
        }
        callback();
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次确认密码"));
      } else if (value !== this.formCustom.newPassword) {
        callback(new Error("两次密码输入不一致"));
      } else {
        callback();
      }
    };

    return {
      formCustom: {
        newPassword: "",
        passwdCheck: "",
        oldPassword: ""
      },
      ruleCustom: {
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" }
        ],
        newPassword: [
          { required: true, validator: validatePass, trigger: "blur" }
        ],
        passwdCheck: [
          { required: true, validator: validatePassCheck, trigger: "blur" }
        ]
      }
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.portal.userInfo
    })
  },
  methods: {
    ...mapActions("portal", ["updatepass"]),
    handleSubmit(name) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          const data = await this.updatepass({
            ...this.formCustom,
            userId: this.userInfo.id
          });
          if (data) {
            this.$Message.success("修改成功!");
          }
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    }
  }
};
</script>

<style lang="less">
.container {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  padding-top: 136px;
  .title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 24px;
    margin: 100px 0 50px;
  }
}
</style>
